import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Image from "./Image";
interface ImageProps {
  src?: string;
  alternateText?: string;
  apiImageUrl?: boolean;
}

const ImageCard = styled.article`
 padding-right:10px;
 padding-top:30px;
 padding-bottom:30px;
 padding-left:10px;
`;


const ImageSlide: FC<ImageProps> = ({ src, alternateText, apiImageUrl }) => {
  return (
    <ImageCard>
      <Image
        src={src}
        alt={alternateText}
        apiImageUrl={apiImageUrl}
        style={{ width: '100%', height: '100%', objectFit: 'cover', maxHeight: '200px', minHeight: '200px', }}
      />
    </ImageCard>

  );
};


ImageSlide.propTypes = {
  src: PropTypes.string,
};

export default ImageSlide;