import { Link } from "gatsby";
import parse from 'html-react-parser';
import React, { FC, useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Box, Flex } from 'rebass';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { down } from 'styled-breakpoints';
import styled from "styled-components";
import { Bold, Button, H3 } from "../../../components/elements";
import Container from "../../../components/elements/Container";
import Heading from "../../../components/elements/Heading";
import Image from "../../../components/elements/Image";
import backgroundBg from "../../../images/side-bg.webp";
import { useSelector } from '../../../store';
import { theme } from "../../../theme";
import { getSortedServices } from "../../../utils/common";
import SideBarMenu from "./sideBarMenu";
import ImageSlide from "../../../components/elements/ImageSlide";
import StyledRichText from "../../../components/elements/StyledRichText";
interface ServiceDetailProps {
  serviceDetail: any;
  selectedService?: any;
}

const ServiceDetailSection = styled.section`
  text-align: center;
  padding: ${props => props.theme.spacing[3]}rem 0;
  ${down('md')}{
    padding: ${props => props.theme.spacing[1]}rem 0;
    }
`;

const ServiceDetailContent = styled.div`
    font-weight: 300;
    font-size: .9rem;
    line-height: 30px !important;
    margin-top: 15px;
    margin-bottom: 30px;
    color: ${(props: any) => (props.theme.colors.mediumGray)};    
    text-align: left;   
  `;

const ServiceImageSlide = styled.div`
  img {
    display: block;
    height: 300px !important;
  }
`;
const BookWrapper = styled.div`
    background: ${(props: any) => (props.theme.colors.white)};
    padding: 25px;
    max-width: 90%;
    margin: auto;
    margin-top: -84px;
    margin-bottom: 3rem;
    position: relative;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);    
    ::after {
    position: absolute;
    content: "";
    height: 50px;
    width: 50px;
    right: 15px;
    bottom: 15px;
    z-index: 0;
    }
    ${down('lg')}{
      max-width: 100%;
      margin-top: 0px;
      }
    }
  `;

const OuterImage = styled.div`
img{
  // ${down('lg')} { 
  //   height: 450px !important;
  // } 
  ${down('md')} { 
    height: 350px !important;
  } 
}
`;


const SidebarNav = styled.nav`
  background:${(props: any) => (props.theme.colors.brown)};
  background-image: url(${backgroundBg});
  width: 300px;
  min-height: 800px;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 10;
  padding: 1rem;
 
  ${down('lg')} { 
    min-height: 550px;
  }
  ${down('xl')} { 
    width: 100%;
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const ServiceView: FC<ServiceDetailProps> = ({ serviceDetail, selectedService }) => {

  const serviceName = selectedService?.name;
  const serviceId = selectedService?.id;
  const [firstWord, setFirstWord] = useState("");
  const [otherWords, setOtherWord] = useState("");

  const morePhotos = serviceDetail?.data?.attributes?.media?.data || [];
  const imgSrc = serviceDetail?.data?.attributes?.detailPageImage?.data?.attributes?.formats?.medium?.url;
  const serviceDesc = serviceDetail?.data?.attributes?.desc ?? '';
  const [services, setServicesData] = React.useState<any>();
  const { records: serviceRecords } = useSelector((state: any) => state.service);
  const serviceLength = serviceRecords?.data?.length;
  const sortedServices = getSortedServices(services);

  const scrollRef = useRef<null | HTMLElement>(null);

  const executeScroll = () => {
    if (scrollRef?.current)
      scrollRef.current.scrollIntoView();
  };
  useEffect(() => {
    setServicesData(serviceRecords?.data);
  }, [serviceLength]);

  useEffect(() => {
    if (serviceName) {
      const firstSpace = serviceName?.indexOf(" ");
      const otherWords = serviceName?.slice(firstSpace);
      setFirstWord(serviceName?.split(" ")[0]);
      setOtherWord(otherWords);
      executeScroll();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName]);
  const sliderSettings = {
    slidesToShow: morePhotos?.length < 3 ? morePhotos?.length : 3,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: theme.breakPoints.lg,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      },
      {
        breakpoint: theme.breakPoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: theme.breakPoints.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <Box
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <Box
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }
  return (
    <ServiceDetailSection ref={scrollRef} id="serviceDetail">
      <Container>

        <Flex flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']} justifyContent='center' >
          <Box m={[2, 3]}
            sx={{
              width: 'calc(25% - 80px)',
              minWidth: '300px',
              '@media screen and (max-width: 1022px)': {
                width: '100%',
                margin: '10px 0px 30px 0px',
              }
            }} >

            <SidebarNav>
              <SidebarWrap>
                <H3 fontFamily={theme.font.families.oswald} margin="30px 20px"><Bold textColor={theme.colors.white} fontFamily={theme.font.families.oswald}>SERVICES LIST</Bold></H3>
                {sortedServices?.map((item: any, index: number) => {
                  return <SideBarMenu item={item} key={index} />;
                })}
              </SidebarWrap>
            </SidebarNav>
          </Box>
          <Box m={[2, 3]}
            sx={{
              width: '75%',
              '@media screen and (max-width: 1022px)': {
                width: '100%',
                margin: '0',
              }
            }}>
            <OuterImage>
              <Image
                src={imgSrc ?? serviceDetail?.data?.attributes?.detailPageImage?.data?.attributes?.url}
                alt={serviceDetail?.data?.attributes?.detailPageImage?.data?.attributes?.alternativeText}
                apiImageUrl={true}
                style={{ maxWidth: '100%', width: '100%', height: '530px', objectFit: 'cover', }}
                className="img1"
              />
            </OuterImage>
            <BookWrapper>
              <Heading
                fontFamily={theme.font.families.oswald}
                fontSize={'1.6rem'}
                underLineWidth={'35%'}
                unlineColor={theme.colors.brown}
                underline={true}
                textFontWeight={theme.fontWeight.semibold}
                boldFontWeight={theme.fontWeight.semibold}
                textColor={theme.colors.brown} text={firstWord}
                boldText={otherWords}
                boldTextColor={theme.colors.black} />
              <StyledRichText>
                <ServiceDetailContent>
                  {parse(serviceDesc)}
                </ServiceDetailContent>
              </StyledRichText>
              {morePhotos?.length > 0 ?
                <>
                  <Heading fontSize={'1.6rem'} textAlign={'left'} textAlignMob={'left'} fontFamily={theme.font.families.oswald} underLineWidth={'35%'} underline={true} unlineColor={theme.colors.brown} textFontWeight={theme.fontWeight.semibold} boldFontWeight={theme.fontWeight.semibold} textColor={theme.colors.brown} text={"More"} boldText={"Photos"} boldTextColor={theme.colors.black} />
                  <ServiceImageSlide>
                    <Slider  {...sliderSettings}>
                      {morePhotos?.map((photo: any, index: number) => (
                        <ImageSlide
                          key={index}
                          src={photo?.attributes?.formats?.small?.url ?? photo?.attributes?.url}
                          alternateText={photo?.attributes?.alternativeText}
                          apiImageUrl={true}
                        ></ImageSlide>
                      ))}
                    </Slider>
                  </ServiceImageSlide>
                </>
                : ""}
              <Flex mt={3}>
                <Link
                  to={`/contact-us`}
                  state={{ selectedServiceId: serviceId, selectedServiceName: serviceName }}
                  style={{ textDecoration: 'none' }}
                >
                  <Button round primary marginTop color={theme.colors.white}
                    backgroundColor={theme.colors.brown} borderColor={theme.colors.brown}>BOOK A SERVICE NOW </Button>
                </Link>
              </Flex>
            </BookWrapper>
          </Box>
        </Flex>
      </Container>
    </ServiceDetailSection>
  );
};

export default ServiceView;