import React, { useState, useEffect } from "react";
import { ServiceDetail } from "../../../types/service";
import Layout from '../../../components/layout';
import ServiceView from './serviceView';
import ServiceBanner from './serviceBanner';
import { api } from "../../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from '../../../store';
import SEO from '../../../components/Seo';

const Index = () => {
  const { selectedService } = useSelector(state => state.service);
  const serviceId = selectedService?.id;
  const [serviceDetail, setServiceDetail] = useState<ServiceDetail>();
  const notify = (message: string) => toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });

  async function fetchServiceData() {
    const msg = `An error while retrievingg service content!`;
    try {
      const params = {
        "populate": "banner,detailPageImage,media,media.data"
      };
      const { status, data } = await api(`/api/services/${serviceId}`, "get", params);
      if (status >= 200 && status <= 299) {
        setServiceDetail(data);
      } else {
        notify(msg);
      }
    } catch (err) {
      console.log(err);
      notify(msg);
    }
  }

  useEffect(() => {
    fetchServiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  return (
    <Layout>
      <SEO title={serviceDetail?.data?.attributes?.title || 'Service Detail'} description={serviceDetail?.data?.attributes?.shortDesc || serviceDetail?.data?.attributes?.bannerDesc} />
      <ServiceBanner serviceDetail={serviceDetail} selectedService={selectedService} />
      <ServiceView serviceDetail={serviceDetail} selectedService={selectedService} />
    </Layout>
  );
};

export default Index;